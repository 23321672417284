export default class TeamsPostModel {
    constructor(item) {
        this._setName(item);
        this._setRank(item);
        this._setPoint(item);
        this._setUpTypeId(item);
        this._setPhoto(item);
        this._setUpRank(item);
        this._setNameRu(item);
    }


    /**
     * set name
     * @param name
     * @private
     */
    _setName({name}) {
        this.name = name;
    }
    /**
     * set name ru
     * @param name_ru
     * @private
     */
    _setNameRu({name_ru}) {
        this.name_ru = name_ru;
    }
    /**
     * set photo
     * @param image
     * @private
     */
    _setPhoto({image}) {
        this.photo = image;
    }

    /**
     * set rank
     * @param rank
     * @private
     */
    _setRank({rank}) {
        this.rank = rank;
    }

    /**
     * set point
     * @param point
     * @private
     */
    _setPoint({point}) {
        this.point = point;
    }

    /**
     * set upTypeId
     * @param upType
     * @private
     */
    _setUpTypeId({upType}) {
        if (upType?.id)
            this.upTypeId = upType.id;
        else this.upTypeId = upType;
    }


    /**
     * set upRank
     * @param upRank
     * @private
     */
    _setUpRank({upRank}) {
        this.upRank = upRank;
    }

}
